import { FC, useState } from "react";

import { Button, Layout, Select } from "antd";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "store";
import { CaseFormSupport } from "components/common";
import ContactUsModal from "components/common/ContactUsModal";
import GetInTouchModal from "components/common/GetInTouchModal";
import { getUserSelector } from "store/user/user.selector";
import contractSuccess from "data/images/support.svg";
import hydraLogoGray from "data/icons/hydra-logo-gray.svg";
import { RouteLinks } from "services/router.service";
import loginIcon from "data/icons/login.svg";

import "./WelcomePage.scss";

const WelcomePage: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);
  const { t, i18n } = useTranslation();

  const [ isContactUsOpen, setIsContactUsOpen ] = useState(false);
  const [ isGetInTouchOpen, setIsGetInTouchOpen ] = useState(false);

  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <Layout className={ `welcome-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <div className="header">
        <Select
          defaultValue={ i18n.language }
          onChange={ handleLanguageChange }
          options={ [
            { value: "en", label: "En" },
            { value: "ru", label: "Ru" },
          ] }
        />

        <NavLink
          className="login-button"
          to={ RouteLinks.AUTH }
        >
          <Button type="primary" style={ { background: "#0C5463", marginLeft: "auto" } }>
            <img src={ loginIcon } alt="" />
            {t("welcomePage.login")}
          </Button>
        </NavLink>
      </div>

      <div className="group">
        <div>
          <h1 className="group-title">
            {t("welcomePage.title")}
          </h1>

          <div className="form-wrapper">
            <CaseFormSupport />

            {/* <div className="info">
              {t("welcomePage.termsAgreement")}{" "}
              <span className="info-link">
                {t("welcomePage.termsLink")}
              </span>
            </div> */}

            <div
              className="info"
              onClick={ () => setIsContactUsOpen(true) }
            >
              {t("welcomePage.needHelp")}{" "}
              <span className="info-link">
                {t("welcomePage.contactUs")}
              </span>
            </div>
          </div>
        </div>

        <div className="group-right">
          <img
            alt=""
            src={ contractSuccess }
          />

          <div className="group-container">
            <div className="group-footer">
              <span>Powered by</span>

              <img
                alt=""
                src={ hydraLogoGray }
              />
            </div>

            <div>
              {t("welcomePage.questionAboutPlatform")}<br/>
              <span className="info-link" onClick={ () => setIsGetInTouchOpen(true) }>
                {t("welcomePage.getInTouch")}
              </span>{" "}
              {t("welcomePage.helpTeam")}
            </div>
          </div>
        </div>
      </div>

      <ContactUsModal
        isContactUsOpen={ isContactUsOpen }
        setIsContactUsOpen={ setIsContactUsOpen }
      />

      <GetInTouchModal
        isGetInTouchOpen={ isGetInTouchOpen }
        setIsGetInTouchOpen={ setIsGetInTouchOpen }
      />
    </Layout>
  )
}

export default WelcomePage;
