import React from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { Helmet } from "react-helmet";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "store";
import "./i18n";

import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

document.body.style.background = "#FFFFF";

root.render(
  <React.StrictMode>
    <Helmet>
      <meta
        name="viewport"
        content="user-scalable=no, viewport-fit=cover, width=device-width"
      />

      <meta
        name="mobile-web-app-capable"
        content="yes"
      />

      <meta
        name="apple-mobile-web-app-capable"
        content="yes"
      />

      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
    </Helmet>

    <BrowserRouter>
      <Provider store={ store }>
        <ConfigProvider theme={ { token: { colorPrimary: "#0C758C" } } }>
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
