import { FC, useCallback, useState } from "react";

import { Button, Form, Input, message, Select } from "antd";
import { useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import userIcon from "data/icons/user.svg";
import emailIcon from "data/icons/email.svg";
import phoneIcon from "data/icons/phone.svg";
import addFileIcon from "data/icons/add-file.svg";
import exclamationIcon from "data/icons/exclamation-circle.svg";
import exclamationIconRed from "data/icons/exclamation-circle-red.svg";
import infoIcon from "data/icons/info-circle.svg";
import infoIconBlue from "data/icons/info-circle-blue.svg";
import warningIcon from "data/icons/warning-circle.svg";
import warningIconOrange from "data/icons/warning-circle-orange.svg";
import attachedFileIcon from "data/icons/attached-file.svg";
import closeIcon from "data/icons/close.svg";
import { formatPhoneNumber } from "services/data.service";
import { useAppDispatch } from "store";
import { Severity } from "store/cases/cases.types";
import api from "services/api.service";
import { ICaseForOpen } from "services/interface.service";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";
import { getCases } from "store/cases/cases.thunks";

import "./CaseFormSupport.scss";

const severities = [
  {
    value: 0,
    label: "Info",
    icon: infoIcon,
    iconActive: infoIconBlue,
    className: "custom-radio-item-info",
  },
  {
    value: 1,
    label: "Minor",
    icon: infoIcon,
    iconActive: infoIconBlue,
    className: "custom-radio-item-info",
  },
  {
    value: 2,
    label: "Major",
    icon: warningIcon,
    iconActive: warningIconOrange,
    className: "custom-radio-item-major",
  },
  {
    value: 3,
    label: "Critical",
    icon: exclamationIcon,
    iconActive: exclamationIconRed,
    className: "custom-radio-item-critical",
  },
]

const initialValues: Partial<ICaseForOpen> = {
  subject: "",
  text: "",
  serial_number: "",
  severity: Severity.INFO,
  author_name: "",
  author_email: "",
  author_phone_number: "",
  contractorId: null,
}

const CaseFormSupport: FC = () => {
  const [ form ] = Form.useForm();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [ stateBool, forceUpdate ] = useState(false);
  const [ files, setFiles ] = useState<File[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isCCActive, setCCActive ] = useState(false);
  const [ additionalEmails, setAdditionalEmails ] = useState<string[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback((acceptedFiles: File[]) => {
      let result: File[] = [];
      acceptedFiles && Object.keys(acceptedFiles).forEach((label) => {
        result.push(acceptedFiles[ +label ]);
      });
      setFiles([ ...files, ...result ]);
    }, []),
    multiple: true,
  });

  const handleCompleteForm = async (values: ICaseForOpen) => {
    try {
      setIsLoading(true);

      let attachments: number[] = [];

      await files.reduce(async (memo, file) => {
        await memo;
        const response = await api.uploadFile(file);

        attachments.push(response.id)
      }, Promise.resolve());

      await api.openPublicCase({ ...values, attachments });

      form.resetFields();
      setIsLoading(false);
      afterCompleteEvent();
      message.success("Case was created.")
    } catch (e: any) {
      setIsLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const afterCompleteEvent = () => {
    form.setFieldsValue(initialValues);
    setFiles([]);
    setAdditionalEmails([]);
    location.pathname === RouteLinks.PROTECTED.CASES.MAIN && dispatch(getCases());
  }

  return (
    <Form
      className="case-form-new"
      name="case-form"
      layout="vertical"
      autoComplete="off"
      form={ form }
      onFinish={ handleCompleteForm }
      initialValues={ initialValues }
    >
      <h3>{ t("caseForm.completeForm") }</h3>

      <Form.Item
        label={ t("caseForm.subject") }
        name="subject"
        rules={ [ { required: true, message: t("validation.subjectRequired") as string } ] }
      >
        <Input
          className="with-count"
          showCount
          maxLength={ 150 }
          placeholder={ t("caseForm.addSubject") as string }
        />
      </Form.Item>

      <Form.Item
        label={
          <>
            { t("caseForm.problemDescription") }
            <label className="add-file-label">
              <img
                src={ addFileIcon }
                alt=""
                style={ { marginRight: "5px" } }
              />
              { t("caseForm.addFile") }
              <input
                type="file"
                style={ { display: "none" } }
                multiple
                onChange={ (e) => {
                  let result: File[] = [];
                  e.target.files && Object.keys(e.target.files).forEach((label) => {
                    result.push(e.target.files![ +label ]);
                  });
                  setFiles([ ...files, ...result ]);
                } }
              />
            </label>
          </>
        }
        name="text"
        className="form-with-button"
      >
        <div
          { ...getRootProps() }
          className={ isDragActive ? "file-dropzone" : "" }
          onClick={ () => {} }
        >
          <input { ...getInputProps() } />
          { isDragActive
            ? <p>{ t("caseForm.dropFiles") }</p>
            : <Input.TextArea style={ { resize: "none", height: "58px" } } />
          }
        </div>
      </Form.Item>

      <div className="files-list">
        { files.map((file) => (
          <div
            key={ file.name }
            className="files-item"
          >
            <img src={ attachedFileIcon } alt="" />
            { file.name }
            <div
              className="cross-file"
              onClick={ () => setFiles(files.filter((fl) => fl.name !== file.name)) }
            >
              <img src={ closeIcon } alt="" />
            </div>
          </div>
        )) }
      </div>

      <Form.Item
        label={ t("caseForm.serialNumber") }
        name="serial_number"
      >
        <Input placeholder={ t("caseForm.addSerialNumber") as string } />
      </Form.Item>

      <Form.Item
        name="severity"
        initialValue="info"
      >
        <div className="custom-radio-group">
          { severities.map((severity) => (
            <div
              className={ `custom-radio-item ${ (
                form.getFieldValue("severity") === undefined
                  ? severity.value === 0
                  : form.getFieldValue("severity") === severity.value)
                ? severity.className : ""
              }` }
              key={ severity.value }
              onClick={ () => {
                form.setFieldsValue({ severity: severity.value })
                forceUpdate(!stateBool);
              } }
            >
              <img src={ form.getFieldValue("severity") === severity.value ? severity.iconActive : severity.icon } alt=""/>
              <p className="custom-radio-title">{ severity.label }</p>
            </div>
          )) }
        </div>
      </Form.Item>

      <div className="user-info">
        <Form.Item
          label={ t("caseForm.contactName") }
          name="author_name"
        >
          <Input
            placeholder={ t("caseForm.name") as string }
            prefix={ <img src={ userIcon } alt="" /> }
          />
        </Form.Item>

        <Form.Item className="mg-btn-0">
          <Form.Item
            className="mg-btn-0"
            label={ t("caseForm.email") }
            name="author_email"
            rules={ [
              { required: true, message: t("validation.emailRequired") as string },
              { type: "email", message: t("validation.emailInvalid") as string },
            ] }
            style={ { display: "inline-block", width: "calc(50% - 10px)", marginRight: "20px" } }
          >
            <Input
              placeholder={ t("caseForm.emailPlaceholder") as string }
              prefix={ <img src={ emailIcon } alt="" /> }
            />
          </Form.Item>

          <Form.Item
            className="mg-btn-0"
            label={ t("caseForm.phone") }
            name="author_phone_number"
            style={ { display: "inline-block", width: "calc(50% - 10px)" } }
          >
            <Input
              placeholder={ t("caseForm.phonePlaceholder") as string }
              prefix={ <img src={ phoneIcon } alt="" style={ { marginRight: "5px" } } /> }
              onChange={ (e) => form.setFieldsValue({
                author_phone_number: formatPhoneNumber(e.target.value),
              }) }
            />
          </Form.Item>
        </Form.Item>
      </div>

      { additionalEmails.length > 0 && (
        <Form.Item style={ { marginBottom: 0 } }>
          { additionalEmails.map((item: string, id: number) => (
            <Form.Item
              key={ id }
              className="mg-btn-0"
              label={ `Email ${ id + 2 }` }
              name={ `author_email-${ id + 2 }` }
              rules={ [ { type: "email", message: "Please input correct email!" } ] }
              style={ { display: "inline-block", width: "calc(50% - 10px)", marginRight: id % 2 === 0 ? "20px" : "0", marginTop: "15px" } }
            >
              <Input
                placeholder="yourname@mail.com"
                prefix={ <img src={ emailIcon } alt="" /> }
                value={ item }
                onChange={ (e) => setAdditionalEmails(additionalEmails.map((mail, index) => index === id ? e.target.value : mail)) }
              />
            </Form.Item>
          )) }
        </Form.Item>
      ) }

      { isCCActive ? (
        <div className="user-info cc-info">
          <Form.Item
            label="CC"
            name="email_cc"
          >
            <Select
              mode="tags"
              optionLabelProp="label2"
              filterOption={ false }
              placeholder={ t("caseForm.nameOrEmail") }
              notFoundContent={ null }
              dropdownStyle={ { visibility: "hidden" } }
            />
          </Form.Item>
        </div>
      ) : (
        <Form.Item className="mg-btn-0">
          <Button
            className="add-email-button"
            type="text"
            onClick={ () => setCCActive(true) }
          >
            + CC
          </Button>
        </Form.Item>
      ) }

      <Form.Item
        className="mg-btn-0"
        style={ { marginTop: "15px" } }
      >
        <Button
          className="big-btn"
          type="primary"
          htmlType="submit"
          disabled={ isLoading }
        >
          <Loading
            isLoading={ isLoading }
            height={ 25 }
            width={ 81 }
          >
            { t("caseForm.openCase") }
          </Loading>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CaseFormSupport;
